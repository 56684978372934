// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

function Login({ setAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Estado de carga

  const navigate = useNavigate(); // Hook para redireccionar

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Activar indicador de carga

    try {
      const response = await axios.post(`${config.apiUrl}/api/auth/login`, { email, password });

      // Guardar el token JWT en el localStorage
      localStorage.setItem('token', response.data.token);
      setAuthenticated(true); // Cambiar el estado de autenticación en el componente principal

      // Redirigir al home después de iniciar sesión
      navigate('/');
    } catch (error) {
      setError('Correo electrónico o contraseña incorrectos');
    } finally {
      setLoading(false); // Desactivar indicador de carga
    }
  };

  return (
    <div className="login-container">
      <div className="login-form fade-in">
        {/* Logo */}
        <div className="logo-container fade-in fade-in-1">
          <img src="/logo.svg" alt="Logo" className="logo" />
        </div>

        {/* Formulario */}
        <form onSubmit={handleSubmit}>
          {/* Campo de correo */}
          <div className="input-icon-group fade-in fade-in-2">
            <i className="fas fa-envelope input-icon"></i> {/* Icono de correo */}
            <input
              type="email"
              id="email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
              placeholder="Correo Electrónico"
            />
          </div>

          {/* Campo de contraseña */}
          <div className="input-icon-group fade-in fade-in-3">
            <i className="fas fa-lock input-icon"></i> {/* Icono de candado */}
            <input
              type="password"
              id="password"
              name="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
              placeholder="Contraseña"
            />
          </div>

          {/* Botón de submit */}
          <div className="submit-group">
            <button
              type="submit"
              className={`submit-button fade-in fade-in-4 ${loading ? 'disabled-button' : ''}`}
              disabled={loading} // Desactivar el botón si está cargando
              style={{
                backgroundColor: loading ? '#ccc' : '#27802e', // Cambia a gris cuando esté deshabilitado
                color: loading ? '#666' : '#fff', // Cambia a gris oscuro para el texto cuando esté deshabilitado
                cursor: loading ? 'not-allowed' : 'pointer',
              }}
            >
              {loading ? 'Cargando...' : 'Iniciar sesión'}
            </button>
          </div>
        </form>

        {/* Mensaje de error */}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}

export default Login;
